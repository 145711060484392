/*
 * @Author: your name
 * @Date: 2022-01-07 16:16:35
 * @LastEditTime: 2022-01-14 16:48:43
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE,
 * @FilePath: \ananyuweiback\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import '@/utils/rem'
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
import router from './router'
import aMap from 'vue-amap' 
import store from './store'
import VueCookies from 'vue-cookies'
// import echarts from 'echarts'//引入图表echarts
import * as echarts from 'echarts';

// 防止重复点击
import preventReClick from './utils/preventReClick'


import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css';
 

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.use(VueCookies);
Vue.use(ElementUI);
Vue.use(aMap)
Vue.use(preventReClick)
Vue.use(VueAwesomeSwiper)

aMap.initAMapApiLoader({
  key: '51113d4a0968cb83919721f58b7f3657' , // 你的key
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch','AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.Geolocation','AMap.Geocoder', 'AMap.AMapManager', 'AMap.Marker'],//应用功能项
  v: '1.4.4', //版本
  uiVersion: '1.0' //ui版本
})


// 按钮权限-自定义指令
Vue.directive('has', {
  inserted: function (el, binding) {
    if (!permissionJudge(binding.value)) {
      el.parentNode.removeChild(el);
    }
    function permissionJudge(value) {
      //  菜单按钮权限code
      let list = localStorage.getItem("codeB");
      let listArr = JSON.parse(list);
      let arr = new String(listArr).split(",")
      for (let item of arr) {
        if (item == value) {
          return true;
        }
      }
      return false;
    }
  }
});


window.vm = new Vue({
  el : '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
