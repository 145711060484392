export default {
  state: {
    //控制设备申请弹窗
    changeappapplication : false,
    
  },
  mutations: {
    ChangeAppApplication(state){
      state.changeappapplication = !state.changeappapplication
    }
  }
}