export default {
  state: {
    //上级部门名称
    upname : '',
    //上级部门id
    upid : '',
    //控制部门新增弹窗
    bmshow : false,
    //控制人员新增弹窗
    usershow : false,
    //最高级id
    kingid : '',
    //最高级name
    kingname : '',
    //部门列表
    lists : [],
    //人员列表
    userlist : [],
    //左侧树形图表
    treelist : [],
    bmshow : false  ,
    bmchangeshow : false
  },
  mutations: {
    ChangeUpname(state ,payload){
      state.upname = payload
      console.log(payload)
    },
    ChangeUpid(state ,payload){
      state.upid = payload
      console.log(payload)
    },
    ChangeBmshow(state ){
      state.bmshow = !state.bmshow
      console.log("changebmshow",state.bmshow)
    },
    ChangeUsershow(state){
      state.usershow = !state.usershow
      console.log("ChangeUsershow",state.usershow)
    },
    ChangeList(state,payload){
      console.log(payload)
      state.lists = payload
    },
    ChangeKingid(state ,payload){
      state.kingid = payload
      console.log(payload)
    },
    ChangeKingname(state ,payload){
      state.kingname = payload
      console.log(payload)
    },
    ChangeUserlist(state,payload){
      console.log(payload)
      state.userlist = payload
    },
    ChangeTreelist(state,payload){
      console.log(payload)
      state.treelist = payload
    },
    ChangeBmchangeshow(state ){
      state.bmchangeshow = !state.bmchangeshow
      console.log("ChangeBmchangeshow",state.bmchangeshow)
    },
  }
}