import Vue from 'vue'
import Vuex from 'vuex'
import user from './models/user'
import frames from "./models/frames"
import maintenance from "./models/maintenance"
import report from "./models/report"
import service from './models/service'
Vue.use(Vuex)



export default new Vuex.Store({
  modules: {
    user,
    frames,
    maintenance,
    report,
    service
  }
})