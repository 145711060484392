export default {
  state: {
    //控制新增维保模板选择页面弹窗
    selectmode : false,
    //控制维保工单模板弹窗
    selectorder : false,
    //控制设备模板弹窗
    selectapplication : false
  },
  mutations: {
    ChangeSelectMode(state){
      state.selectmode = !state.selectmode
    },
    ChangeOrder(state){
      state.selectorder = !state.selectorder
    },
    ChangeApplication(state){
      state.selectapplication = !state.selectapplication
    }
  }
}