import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Homes'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Homes',
    component: Home,
    redirect: '/homes/home',
    meta: {
      requireAuth: true,  // 除此路由外，其他都需登录
    },
    children: [
      {
        //首页
        path: '/homes/home',
        name: 'Home',
        meta: {
          requireAuth: true,  // 除此路由外，其他都需登录
        },
        component: () => import(/* webpackChunkName: "home" */ '../views/Homes/Home')
      },
      {
        //登录页
        path: '/homes/login',
        name: 'Login',
        meta: {
          requireAuth: true,  // 除此路由外，其他都需登录
        },
        component: () => import(/* webpackChunkName: "login" */ '../views/Homes/Login')
      },
      {
        //注册页
        path: '/homes/register',
        name: 'Register',
        meta: {
          requireAuth: true,  // 除此路由外，其他都需登录
        },
        component: () => import(/* webpackChunkName: "register" */ '../views/Homes/Register')
      },
      {
        //注册类型选择入口
        path: '/homes/registerenter',
        name: 'Registerenter',
        meta: {
          requireAuth: true,  // 除此路由外，其他都需登录
        },
        component: () => import(/* webpackChunkName: "registerenter" */ '../views/Homes/Registerenter')
      }
    ]
  },
  {
    //登录页
    path: '/homelogin',
    name: 'Homelogin',
    component: () => import(/* webpackChunkName: "homelogin" */ '../views/Homelogin')
  },
  {
    path: '/main',
    name: 'Main',
    component: () => import(/* webpackChunkName: "main" */ '../views/Main'),
    // redirect : '/main/framework',
    meta: {
      keepAlive: true // 需要被缓存
    },
    children: [
      {
        //组织架构页面
        path: '/main/framework',
        name: 'Framework',
        component: () => import(/* webpackChunkName: "framework" */ '../views/Main/Framework'),
        redirect: '/main/framework/frames',
        meta: {
          keepAlive: true // 需要被缓存
        },
        children: [
          {
            //组织架构页面
            path: '/main/framework/frames',
            name: 'Frames',
            component: () => import(/* webpackChunkName: "frames" */ '../views/Main/Framework/Frames'),
            meta: {
              keepAlive: true // 需要被缓存
            },
          },
        ]
      },
      {
        //账户页面
        path: '/main/account',
        name: 'Account',
        component: () => import(/* webpackChunkName: "account" */ '../views/Main/Account'),
        redirect: '/main/account/eiimprovement',
        children: [
          {
            //企业信息完善
            path: '/main/account/eiimprovement',
            name: 'EIImprovement',
            component: () => import(/* webpackChunkName: "eiimprovement" */ '../views/Main/Account/EIImprovement'),
          },
          {
            //密码修改
            path: '/main/account/changepwd',
            name: 'Changepwd',
            component: () => import(/* webpackChunkName: "account" */ '../views/Main/Account/ChangePwd'),
          },
          {
            //设备管理界面
            path: '/main/account/equipment',
            name: 'equipment',
            component: () => import(/* webpackChunkName: "news" */ '../views/Main/Account/equipment')
          },
        ]
      },
      {
        //监管界面
        path: '/main/check',
        name: 'Check',
        component: () => import(/* webpackChunkName: "check" */ '../views/Main/Check'),
        redirect: '/main/check/checkmain',
        children: [
          {
            //工单统计界面
            path: '/main/check/biostatistical',
            name: 'Biostatistical',
            component: () => import(/* webpackChunkName: "plan" */ '../views/Main/Check/Biostatistical'),
          },
          {
            //客户分析界面
            path: '/main/check/analysis',
            name: 'Analysis',
            component: () => import(/* webpackChunkName: "plan" */ '../views/Main/Check/Analysis'),
          },
          {
            //营收报表界面
            path: '/main/check/subreport',
            name: 'Subreport',
            component: () => import(/* webpackChunkName: "plan" */ '../views/Main/Check/Subreport'),
          },
          {
            //人员地图界面
            path: '/main/check/sortedmap',
            name: 'SortedMap',
            component: () => import(/* webpackChunkName: "plan" */ '../views/Main/Check/SortedMap'),
          },
          {
            //服务商分析界面
            path: '/main/check/serviceprovider',
            name: 'ServiceProvider',
            component: () => import(/* webpackChunkName: "plan" */ '../views/Main/Check/ServiceProvider'),
          },
          {
            //费用统计界面
            path: '/main/check/patcost',
            name: 'PatCost',
            component: () => import(/* webpackChunkName: "plan" */ '../views/Main/Check/PatCost'),
          },
          {
            //监管总览界面
            path: '/main/check/checkmain',
            name: 'CheckMain',
            component: () => import(/* webpackChunkName: "plan" */ '../views/Main/Check/CheckMain'),
          },
          {
            //平台监管
            path: '/main/check/regulatory',
            name: 'regulatory',
            component: () => import(/* webpackChunkName: "services" */ '../views/Main/Check/Regulatory'),
          },
          {
            //柱状图
            path: '/main/check/histogram',
            name: 'histogram',
            component: () => import(/* webpackChunkName: "services" */ '../views/Main/Check/histogram'),
          },
          {
            //报障工单
            path: '/main/check/reportorder',
            name: 'reportorder',
            component: () => import(/* webpackChunkName: "services" */ '../views/Main/Check/ReportOrder'),
          },
          {
            //维保工单
            path: '/main/check/maintenanceorder',
            name: 'maintenanceorder',
            component: () => import(/* webpackChunkName: "services" */ '../views/Main/Check/MaintenanceOrder'),
          },
        ]
      },
      {
        //维保界面
        path: '/main/maintenance',
        name: 'Maintenance',
        component: () => import(/* webpackChunkName: "maintenance" */ '../views/Main/Maintenance'),
        redirect: '/main/maintenance/plan',
        children: [
          {
            //维保计划界面
            path: '/main/maintenance/plan',
            name: 'Plan',
            component: () => import(/* webpackChunkName: "plan" */ '../views/Main/Maintenance/Plan'),
          },
          {
            //维保计划界面
            path: '/main/maintenance/plan/addlanys',
            name: 'Asas',
            component: () => import(/* webpackChunkName: "plan" */ '../views/Main/Maintenance/Plan/addlanys'),
          },
          {
            //维保计划查看界面
            path: '/main/maintenance/plan/view',
            name: 'view',
            component: () => import(/* webpackChunkName: "plan" */ '../views/Main/Maintenance/Plan/view'),
          },
          {
            //维保工单界面
            path: '/main/maintenance/order',
            name: 'Order',
            component: () => import(/* webpackChunkName: "order" */ '../views/Main/Maintenance/Order'),
          },
          {
            //维保模板界面
            path: '/main/maintenance/mode',
            name: 'Mode',
            component: () => import(/* webpackChunkName: "mode" */ '../views/Main/Maintenance/Mode'),
          },
          {
            //维保工单模板界面
            path: '/main/maintenance/mode/ordertempl',
            name: 'Ordertemplate',
            component: () => import(/* webpackChunkName: "plan" */ '../views/Main/Maintenance/Mode/ordertemplate'),
          },
          {
            //维保工单详情界面
            path: '/main/maintenance/order/main',
            name: 'Main',
            component: () => import(/* webpackChunkName: "ordermain" */ '../views/Main/Maintenance/Order/main'),
          },
          {
            //维保工单监管查看详情界面
            path: '/main/maintenance/order/mainjg',
            name: 'MainJg',
            component: () => import(/* webpackChunkName: "mainjg" */ '../views/Main/Maintenance/Order/mainJg'),
          },
          {
            //设备模板界面
            path: '/main/maintenance/mode/equipment',
            name: 'Equipmenttemplate',
            component: () => import(/* webpackChunkName: "plan" */ '../views/Main/Maintenance/Mode/equipmenttemplate'),
          },
          //维保的设备申请界面
          {
            path: '/main/maintenance/application',
            name: 'Application',
            component: () => import(/* webpackChunkName: "application" */ '../views/Main/Maintenance/Application'),
          },
        ]
      },
      {
        //消息界面
        path: '/main/news',
        name: 'News',
        component: () => import(/* webpackChunkName: "news" */ '../views/Main/News')
      },
      {
        //报障界面
        path: '/main/report',
        name: 'Report',
        component: () => import(/* webpackChunkName: "report" */ '../views/Main/Report'),
        redirect: "/main/report/order",
        children: [
          {
            //报障工单界面
            path: '/main/report/order',
            name: 'Order',
            component: () => import(/* webpackChunkName: "order" */ '../views/Main/Report/Order'),
          },
          {
            //报障申请界面
            path: '/main/report/orderapply',
            name: 'Orderapply',
            component: () => import(/* webpackChunkName: "orderapply" */ '../views/Main/Report/Orderapply'),
          },
          {
            //报障申请界面
            path: '/main/report/applysq',
            name: 'Applysq',
            component: () => import(/* webpackChunkName: "orderapply" */ '../views/Main/Report/applysq'),
          },
          {
            //设备申请界面
            path: '/main/report/appapply',
            name: 'Appapply',
            component: () => import(/* webpackChunkName: "appapply" */ '../views/Main/Report/Appapply'),
          },
          {
            //报障工单详情界面
            path: '/main/report/order/ordermain',
            name: 'OrderMain',
            component: () => import(/* webpackChunkName: "ordermain" */ '../views/Main/Report/Order/OrderMain.vue'),
          },
          {
            //报障工单监管查看详情界面
            path: '/main/report/order/ordermainjg',
            name: 'OrderMainJg',
            component: () => import(/* webpackChunkName: "ordermain" */ '../views/Main/Report/Order/OrderMainJg.vue'),
          },
        ]
      },
      {
        //服务商界面
        path: '/main/service',
        name: 'Service',
        component: () => import(/* webpackChunkName: "service" */ '../views/Main/Service'),
        redirect: '/main/service/services',
        children: [
          {
            //服务商界面
            path: '/main/service/services',
            name: 'services',
            component: () => import(/* webpackChunkName: "services" */ '../views/Main/Service/Services'),
          }
        ]
      },
      {
        //需求方界面
        path: '/main/demandside',
        name: 'DemandSide',
        component: () => import(/* webpackChunkName: "service" */ '../views/Main/DemandSide'),
        redirect: '/main/demandside/demandsides',
        children: [
          {
            //需求方界面
            path: '/main/demandside/demandsides',
            name: 'demandsides',
            component: () => import(/* webpackChunkName: "services" */ '../views/Main/DemandSide/DemandSides'),
          }
        ]
      },
      {
        //平台方界面
        path: '/main/platform',
        name: 'Platform',
        component: () => import(/* webpackChunkName: "service" */ '../views/Main/Platform'),
        redirect: '/main/platform/platforms',
        children: [
          {
            //资质审核界面
            path: '/main/platform/platforms',
            name: 'platforms',
            component: () => import(/* webpackChunkName: "services" */ '../views/Main/Platform/Platforms'),
          },
          {
            //详情
            path: '/main/platform/platforms/mainplatforms',
            name: 'mainplatforms',
            component: () => import(/* webpackChunkName: "services" */ '../views/Main/Platform/Platforms/mainplatforms'),
          },
        ]
      },
      {
        //企业管理界面
        path: '/main/enterprise',
        name: 'Enterprise',
        component: () => import(/* webpackChunkName: "service" */ '../views/Main/Enterprise'),
        redirect: '/main/enterprise/serviceManagement',
        children: [
          {
            //服务商管理
            path: '/main/enterprise/serviceManagement',
            name: 'ServiceManagement',
            component: () => import(/* webpackChunkName: "services" */ '../views/Main/Enterprise/serviceManagement'),
          },
          {
            //详情
            path: '/main/enterprise/serviceManagement/info',
            name: 'Info',
            component: () => import(/* webpackChunkName: "services" */ '../views/Main/Enterprise/serviceManagement/info'),
          },
          {
            //需求方管理
            path: '/main/enterprise/demandManagement',
            name: 'DemandManagement',
            component: () => import(/* webpackChunkName: "services" */ '../views/Main/Enterprise/demandManagement'),
          },
          {
            //详情
            path: '/main/enterprise/demandManagement/info',
            name: 'Info',
            component: () => import(/* webpackChunkName: "services" */ '../views/Main/Enterprise/serviceManagement/info'),
          },
        ]
      },
      {
        //绑定关系界面
        path: '/main/relationship',
        name: 'Relationship',
        component: () => import(/* webpackChunkName: "service" */ '../views/Main/relationship'),
        redirect: '/main/relationship/relation',
        children: [
          {
            //绑定关系
            path: '/main/relationship/relation',
            name: 'Relation',
            component: () => import(/* webpackChunkName: "services" */ '../views/Main/relationship/relation'),
          },
        ]
      },
      {
        //星级评定
        path: '/main/starrating',
        name: 'Starrating',
        component: () => import(/* webpackChunkName: "account" */ '../views/Main/Starrating'),
        redirect: '/main/starrating/starratins',
        children: [
          {
            //星级评定 (需求服务商)
            path: '/main/starrating/starratins',
            name: 'Starratings',
            component: () => import(/* webpackChunkName: "eiimprovement" */ '../views/Main/Starrating/Starratings'),
          },
        ]
      },
      {
        //星级评定（平台）
        path: '/main/ptstarrating',
        name: 'ptstarrating',
        component: () => import(/* webpackChunkName: "ptstarrating" */ '../views/Main/ptstarrating'),
        redirect: '/main/ptstarrating/starratinlist',
        children: [
          {
            //星级评定 平台列表
            path: '/main/ptstarrating/starratinlist',
            name: 'Starratinlist',
            component: () => import(/* webpackChunkName: "Starratinlist" */ '../views/Main/ptstarrating/Starratinglist'),
          },
          {
            //星级评定详情
            path: '/main/ptstarrating/starratinlist/order',
            name: 'Starratings',
            component: () => import(/* webpackChunkName: "Starratings" */ '../views/Main/ptstarrating/Starratings'),
          },
         
        ]
      },
    ]
  },
]


const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (!to.meta.requireAuth) {
    if ($cookies.get('token')) {
      next();
    } else {
      next({ path: '/homes/home' })
    }
  } else {
    next()
  }
})
export default router
