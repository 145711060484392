export default {
  state: {
    //控制设备申请弹窗
    changeservice : false,
    
  },
  mutations: {
    ChangeService(state){
      state.changeservice = !state.changeservice
      console.log(state.changeservice)
    }
  }
}