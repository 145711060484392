//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      lists: [],
    }
  },
  mounted() {
    console.log(
      this.$route.query.login,
      JSON.parse(localStorage.getItem('returnA')),
    )
    if (this.$route.query.login) {
      this.lists = JSON.parse(localStorage.getItem('returnA'))
    }
  },
  methods: {
    //denglu
    login() {
      this.$router.push('/homes/login')
    },
    //控制台
    logins() {
      // console.log(this.lists[0].children[0].path)
      this.lists = JSON.parse(localStorage.getItem('returnA'))
      console.log(this.lists);
      this.$router.push(this.lists[0].children[0].path)
      // this.$router.push('/main/report')
    },
    // 加入安安
    joinbtn() {
      // this.$router.push('/homes/registerenter')
      this.goTo('ininin')
    },
    goTo(e) {
      let idName = document.querySelector(`#${e}`)
      idName.scrollIntoView()
    },
    servivestar() {
      // this.$router.push("/homes/home#servivestar")
      location.hash = '#servivestar'
    },
    certificate() {
      // this.$router.push("/homes/home#servivestar")
      location.hash = '#certificate'
    },
    homes() {
      // console.log(this.$route.query.login);
      // if (this.$route.query.login) {
      //   this.$router.push('/main/starrating/starratins')
      // } else {
        this.$router.push('/homes/home')
      // }
      // /main/starrating/starratins
    },
  },
}
