export default {
  state: {
    token: '',
    register : false,
    type : ''
  },
  getters:{
  },
  mutations: {
    UPDATE_TOKEN(state, payload) {
      console.log("changetoken")
      state.token = payload
    },
    ChangeRegister(state, payload) {
      console.log("Register")
      state.register = true
    },
    ChangeUserType(state, payload){
      console.log("ChangeUserType",payload)
      state.type = payload
    }
  }
}