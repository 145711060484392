import Vue from 'vue'

const preventReClick = function(){
    let openDalay = false
    //防止重复点击事件
    Vue.directive('preventReClick', {
        inserted (el, binding) {
            el.addEventListener('click', () => {
                if (!el.disabled) {
                    el.disabled = true
                    setTimeout(() => {
                       el.disabled = false
                    }, binding.value || 2000)
                }
            })
        }
    })
}
export default preventReClick